<template>
<div>

    <div v-if="loading" style="float: left;position: absolute;left: 50%;width: 100%;align-items: center;/* justify-content: center; */align-content: center;/* justify-items: center; */justify-items: center;justify-content: center;">
        <vue-loaders name="ball-beat" color="red" scale="1">
            Yükleniyor..
        </vue-loaders>
    </div>
    <div>
        <b-card class="profile-header mb-2" body-class="p-0">
            <b-card-body>
                <b-row class="mb-2">
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                        <label>Anket Tipi</label>
                        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.name" :reduce="val => val.id" />
                    </b-col>

                </b-row>
                <b-row>
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                        <b-button block variant="success" @click="getStudent()">FİLTRELE</b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
    <b-card v-if="users" no-body class="card-company-table">
        <b-table :items="users" responsive :fields="fields" class="mb-0">
            <!-- Id -->
            <template #cell(id)="data">
                <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
            </template>

            <!-- CreatedAt Time -->
            <template #cell(createdAt)="data">
                <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
            </template>

            <!-- Status -->
            <template #cell(isCorrect)="data">
                <b-badge pill :variant="data.item.isCorrect?'light-success':'light-danger'">
                    {{data.item.isCorrect?'ONAYLANDI':'ONAYLANMADI'}}
                </b-badge>
            </template>

            <!-- Actions -->
            <template #cell(actions)="data">
                <b-button variant="flat-primary" @click="openDetails(data.item.id)">
                    <feather-icon icon="LinkIcon" class="mr-50" />
                    <span class="align-middle">Cevaplar</span>
                </b-button>
                <b-button variant="flat-success" @click="appectHistory(data.item)">
                    <feather-icon icon="CheckIcon" class="mr-50" />
                    <span class="align-middle">Onayla</span>
                </b-button>
            </template>

        </b-table>

    </b-card>
    <b-modal v-model="isSecurityShow" hide-header-close no-close-on-backdrop no-close-on-esc id="modal-login" cancel-variant="outline-secondary" ok-title="Listele" cancel-title="İptal" centered close-disabled @ok="checkSecure" title="Özel Alan">
        <b-form>
            <b-form-group>
                <label for="password">Şifre</label>
                <b-form-input type="password" v-model="password" placeholder="Lütfen Özel Şifreyi Giriniz!" />
            </b-form-group>
        </b-form>
    </b-modal>
</div>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BOverlay,
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BCardBody,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import XLSX from 'xlsx';
export default {
    components: {
        ToastificationContent,
        BCard,
        vSelect,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BOverlay,
        BProgress,
        BProgressBar,
        BRow,
        BCol,
        BCardBody,
        BNavbar,
        BNavbarToggle,
        BCollapse,
        BTabs,
        BNavItem
    },
    data() {
        return {
            filters: {},
            selectedLesson: '',
            lessons: [],
            isSecurityShow: false,
            password: '',
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'code', label: 'Anket Kodu' },
                { key: 'name', label: 'Anket Adı' },
                { key: 'uClass', label: 'Öğrenci Sınıfı' },
                { key: 'firstName', label: 'Öğrenci Adı' },
                { key: 'lastName', label: 'Öğrenci Soyadı' },
                { key: 'idNumber', label: 'Öğrenci TC' },
                { key: 'isCorrect', label: 'Durum' },
                { key: "actions", label: 'İşlemler' }
            ],
            users: [],

            loading: false
        }
    },
    mounted() {
        this.$root.$on('ExportData', () => {
            this.exportTable();
        });
    },
    created() {
        //this.getData();
        this.getLesson();
    },
    watch: {

    },
    methods: {
        checkSecure(e) {
            e.preventDefault()
            if (this.password == "190386") {
                this.isSecurityShow = false;
                this.getLesson();
            } else {
                this.isSecurityShow = true;
            }
        },
        async getStudent() {
            this.getData();
        },
        async getLesson() {
            var lesson = await this.$http.get("Branch/Surveys");
            this.lessons = lesson.data.data;

        },
        async getData() {
            var users = await this.$http.get("Teacher/GetAllSurveyHistories/" + this.selectedLesson);

            //console.log(this.selectedLesson);
            this.users = users.data.data;
            // this.users = users.data.data.filter(x => x.name == this.selectedLesson);
        },
        openDetails(id) { //http://localhost:8080
            var url = "https://online.kurssenle.com/watchSurvey/" + id + "?token=" + localStorage.getItem("accessToken")
            window.open(url, "Cevaplar", 'fullscreen="yes"');
        },
        async appectHistory(history) {
            var users = await this.$http.get("Teacher/AppectSurveyHistory/" + history.id);
            history.isCorrect = true;
        },
        async exportTable() {
            let excelItems = [];
            this.loading = true;
            for (let index = 0; index < this.users.length; index++) {
                const element = this.users[index];
                var response = await this.$http.get("Teacher/SurveyWatching/" + element.id);
                var url = "https://online.kurssenle.com/watchSurvey/" + element.id + "?token=" + localStorage.getItem("accessToken");
                var pushData = {
                    "Kayıt Tarihi": element.createdAt,
                    "Anket Adı": element.name,
                    "Öğrenci Adı": element.firstName,
                    "Öğrenci Soyadı": element.lastName,
                    "Sınıfı": element.uClass,
                    "Durum": element.isCorrect == true ? 'Onaylandı' : "Onaylanmadı",
                };
                var grpData = response.data.data.surveyGroups.filter(x => x.id == 'efaa0c57-c81d-4c99-a2c1-0e91eec0ebb1')[0];
                if (grpData != undefined) {
                    grpData.surveyQuestions.forEach(file => {
                        pushData[file.text] = file.userText != undefined ? 'YÜKLENDİ' : 'YÜKLENMEDİ';
                    });
                }
                excelItems.push(pushData);
            }
            let data = XLSX.utils.json_to_sheet(excelItems);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            await XLSX.writeFile(wb, ' Rapor.xlsx');
            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
